#achievePageContainer{
    
}

#achieveTitleContainer{
    /* padding-top: 70px; */
    /* padding-top: 45px; */
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    padding-bottom: 20px;
    opacity: 0;
}

#workContainer{
    display: flex;
    font-size: 10px;
    justify-content: space-around;
    /* gap: 0px; */
    /* margin-top: 30px; */
    margin-top: -7px;
    padding-bottom: 20px;
}

.job {
    /* background-color: #6246ea; */
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    /* max-width: 400px; */
    max-width: 300px;
    text-align: center;
    border-style: solid;
    align-items: center;
    border-color: #6246ea;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 9px 10px 3px 0px rgb(98 70 234 / 76%);
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    transition: ease-in-out 0.3s;
    height: 340px;
}

.jobDesc{
    /* background-color: #6246ea; */
    text-align: center;
    transition: ease-in-out 0.3s;
    font-size: 12px;
}

#jobDesc1{
    /* display: none; */
    opacity: 0;
    pointer-events: none;
}

#jobDesc2{
    opacity: 0;
    pointer-events: none;
}

#jobDesc3{
    opacity: 0;
    pointer-events: none;
}

#job1{
    /* padding-left: 20px;
    padding-right: 20px; */
    opacity: 0;
}

#job2{
    /* padding-left: 20px;
    padding-right: 20px; */
    opacity: 0;
}

#job3{
    /* padding-left: 21px;
    padding-right: 21px; */
    opacity: 0;
}

.jobh1{
    /* background-color: #775fe9; */
    text-align: center;
}

.jobh2{
    /* background-color: #775fe9; */
    text-align: center;
    margin-top: -7px;
    font-weight: 1000;
}

#kiwi_WebsitePic{
    margin-top: 10px;
    border-radius: 10px;
    transition: ease-in-out 0.3s;
    height: 177px;
    min-height: 177px;
    width: 266px;
    border-style: ridge;
    border-color: black;
    border-width: 3px;

    background-color: #6146ea00;
}

#kiwi_WebsitePic:hover {
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
    transition: ease-in-out 0.2s;
    cursor: pointer;

    /* box-shadow: rgba(98, 70, 234, 0.76) 5px 6px 7px 0px; */
}

#modsvr_Pic{
    /* margin-top: 19px;
    border-radius: 10px;
    transition: ease-in-out 0.3s;
    min-height: 177px;

    width: 200px;
    height: 200px;
    background-color: #6146ea00; */

    margin-top: 3px;
    border-radius: 10px;
    transition: ease-in-out 0.3s;

    height: 177px;
    min-height: 177px;
    width: 266px;
    border-style: ridge;
    border-color: black;
    border-width: 3px;

    background-color: #6146ea00;
}

#modsvr_Pic:hover {
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
    transition: ease-in-out 0.2s;
    cursor: pointer;
    box-shadow: rgba(98, 70, 234, 0.76) 5px 6px 7px 0px;
}

#infotech_logo {
    width: 150px;
    max-height: 100px;
    margin-top: 1px;
    padding: 5px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0);
}

#ufit_logo {
    width: 85px;
    height: 50px;
    margin-top: 1px;
    border-radius: 3px;
}

#disney_logo {
    width: 120px;
    height: 90px;
    margin-top: 1px;
    padding: 5px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0);
}

#ufit_Pic{
    /* margin-top: 19px;
    min-height: 177px;
    border-radius: 10px;
    transition: ease-in-out 0.3s;
    width: 200px;
    height: 150px;

    background-color: #6146ea00; */

    margin-top: 10px;
    border-radius: 10px;
    transition: ease-in-out 0.3s;

    height: 177px;
    min-height: 177px;
    width: 266px;
    border-style: ridge;
    border-color: black;
    border-width: 3px;

    background-color: #6146ea00;
}

#ufit_Pic:hover {
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
    transition: ease-in-out 0.2s;
    cursor: pointer;
    box-shadow: rgba(98, 70, 234, 0.76) 5px 6px 7px 0px;
}

#awardsContainer{
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
    opacity: 0;
}

#achieveSubtitle{
    font-size: 40px;
    /* margin-top: -10px; */
    margin-top: -30px;
}

.award{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.awardImg{
    width: 50px;
    height: 50px;
}

.awardText1{
    font-weight: 600;
}

.awardText2{
    font-weight: 400;
    margin-top: -13px;
}

@keyframes loadFromBtmTitle {
    0% {
        transform: translateY(400px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 100%;
      }
  }

@keyframes loadFromBtm {
    0% {
        transform: translateY(400px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 100%;
      }
  }

.snappedLBTitle{
    animation: 1s ease-out 0.5s 1 loadFromBtmTitle;
    animation-fill-mode: forwards;  
}

.snappedLBLate{
    animation: 1s ease-out 4.5s 1 loadFromBtm;
    animation-fill-mode: forwards;  
}

@media(max-width: 1800px) {
    .job {
        height: 370px;
    }
}

@media(max-width: 970px){
    .job{
        max-width: 30vw;
    }

    /* .job img {
        width: 26vw !important;
    } */

    #kiwi_WebsitePic {
        width: 26vw;
    }

    #modsvr_Pic{
        width: 26vw;
    }

    #ufit_Pic{
        width: 26vw;
    }

    .awardText1{
        text-align: center;
    }
    
    .awardText2{
        text-align: center;
    }

    #awardsContainer{
        display: flex;
    }
}

@media(max-width: 500px) and (min-height: 851px) {
    #achieveTitleContainer{
        font-size: 6vw;
        /* padding-top: 110px; */
        padding-top: 40px;
        text-align: center;
    }

    #achieveTitle{
        font-size: 9vw;
        margin-bottom: 30px;
    }

    #achieveSubtitle{
        font-size: 7vw;

    }

    #workContainer{
        flex-direction: column;
        align-items: center;
    }

    .job{
        width: 60vw;
        max-width: 60vw;
        height: 21vh;
    }

    .jobh1{
        margin: 0;
        font-size: 3vw;
    }

    .jobh2{
        margin-top: 0;
        font-size: 3vw;
    }

    .achieveImg {
        
        margin-top: 0;
    }

    #kiwi_WebsitePic {
        margin-top: 0;
        width: 50vw;
        min-height: 115px;
    }

    #modsvr_Pic{
        margin-top: 0;
        width: 50vw;
        min-height: 115px;
    }

    #ufit_Pic{
        margin-top: 0;
        width: 50vw;
        min-height: 115px;
    }

    #workContainer{
        gap: 22px;
    }

    #awardsContainer{
        display: none;
    }

    #infotech_logo {
        width: 100px;
        max-height: 50px;
        margin-top: -10px;
    }
    
    #ufit_logo {
        width: 70px;
        height: 35px;
        margin-top: -8px;
        margin-bottom: 3px;
    }
    
    #disney_logo {
        width: 70px;
        height: 40px;
        margin-top: -10px;
    }

    .job {
        height: 20.2vh;
    }
}

@media(max-height: 850px) and (max-width: 500px) {
    #achieveTitleContainer{
        font-size: 6vw;
        /* padding-top: 110px; */
        padding-top: 40px;
        text-align: center;
    }

    #achieveTitle{
        font-size: 9vw;
        margin-bottom: 30px;
    }

    #achieveSubtitle{
        font-size: 7vw;

    }

    #workContainer{
        flex-direction: column;
        align-items: center;
        margin-top: -22px;
    }

    .job{
        width: 60vw;
        max-width: 60vw;
        height: 20.2vh;
    }

    .jobh1{
        margin: 0;
        font-size: 3vw;
    }

    .jobh2{
        margin-top: 0;
        font-size: 3vw;
    }

    .achieveImg {
        
        margin-top: 0;
    }

    #kiwi_WebsitePic {
        margin-top: 0;
        width: 40vw;
        min-height: 70px;
    }

    #modsvr_Pic{
        margin-top: 0;
        width: 40vw;
        min-height: 70px;
    }

    #ufit_Pic{
        margin-top: 0;
        width: 40vw;
        min-height: 70px;
    }

    #workContainer{
        gap: 22px;
    }

    #awardsContainer{
        display: none;
    }
    #infotech_logo {
        width: 100px;
        max-height: 50px;
        margin-top: -10px;
    }
    
    #ufit_logo {
        width: 70px;
        height: 35px;
        margin-top: -8px;
        margin-bottom: 3px;
    }
    
    #disney_logo {
        width: 70px;
        height: 40px;
        margin-top: -10px;
    }
}

@media(min-height: 870px) and (min-width: 500px) {
    #workContainer {
        display: flex;
        font-size: 10px;
        justify-content: space-around;
        margin-top: 30px;
        padding-bottom: 20px;
    }
}