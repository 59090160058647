#projectInfoBackground{
    /* position: fixed;
    background: #00000073;
    height: 100vh;
    top: 0;
    width: 100%; */
    transition: 0.4s ease-in-out;
}

.projectShadow{
    position: fixed;
    background: #00000073;
    height: 100vh;
    top: 0;
    width: 100%;
    z-index: 1;
}


#projectTitleContainer{
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    padding-bottom: 20px;
    opacity: 0;
}

#projectTitle{

}

#projectContainer{
    display: flex;
    flex-direction: column;
}

.projectSubBox{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#projectSubTitle{
    font-size: 40px;
    margin-top: -40px;
}

#projectInfoBox{
    /* position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    background-color: bisque;
    width: 50%;
    height: 50vh;
    transition: ease-in-out 0.4s; */

    position: fixed;
    /* top: 25%; */
    top: 20%;



    /* left: 0%; */
    display: flex;
    transform: translateX(-100%);
    /* background-color: black; */
    width: 100%;
    height: 69vh;
    transition: ease-in-out 0.4s;
    justify-content: center;
}

@media (max-width: 500px) and (min-height: 851px) {
    #projectTitleContainer{
      padding-top: 75px;
    }
  
    #projectTitle{
      font-size: 9vw;
      margin-bottom: 60px;
    }
  
    #projectSubTitle{
      font-size: 7vw;
    }

    .projectSubBox{
        flex-wrap: wrap;
    }

    #projectImg{
      width: 180px;
      height: 80px;
    }

    #projectContainer{
        flex-direction: row;
    }
}

@media(max-width: 500px) {
    #projectInfoBox{
        top: 10%;
    }

    #projectTitleContainer{
        padding-top: 40px;
      }

      #projectContainer{
        margin-top: -25px;
      }
    
      #projectTitle{
        font-size: 9vw;
        margin-bottom: 60px;
      }
    
      #projectSubTitle{
        font-size: 7vw;
        margin-top: -45px;
      }
  
      .projectSubBox{
          flex-wrap: wrap;
      }
  
      #projectImg{
        width: 180px;
        height: 80px;
      }
  
      #projectContainer{
          flex-direction: row;
      }

      #project{
        margin-bottom: 20px;
      }

      #proTitle{
        font-size: 5vw;
        margin: 13px;
        margin-top: 5px;
      }

      .tool{
        padding-bottom: 0;
      }
}