#projectImg{
    width: 220px;
    height: 120px;
}

#project{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-color: #6246ea;
    border-style: solid;
    transition: ease-in-out 0.3s;
    border-style: ridge;
    border-width: 3px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    cursor: pointer;
    opacity: 0;
    /* For Dark Mode #31313; */
}

#project:hover{
    box-shadow: 9px 10px 3px 0px rgb(98 70 234 / 76%);
}

#proTitle{
    background-color: white;
}

#skillsBox{
    display: flex;
    flex-direction: row;
    background-color: white;
}

.tool{
    font-size: 10px;
    margin-top: -10px;
    background-color: white;
    padding-bottom: 4px;
    color: #6246ea;
    padding-right: 4px;
}

@keyframes loadFromBtm {
    0% {
        transform: translateY(400px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 100%;
      }
  }

.snappedLBP1{
    animation: 1s ease-out 1.5s 1 loadFromBtm;
    animation-fill-mode: forwards; 
}

.snappedLBP2{
    animation: 1s ease-out 2s 1 loadFromBtm;
    animation-fill-mode: forwards; 
}

.snappedLBP3{
    animation: 1s ease-out 2.5s 1 loadFromBtm;
    animation-fill-mode: forwards; 
}

.snappedLBP4{
    animation: 1s ease-out 3s 1 loadFromBtm;
    animation-fill-mode: forwards; 
}

.snappedLBP5{
    animation: 1s ease-out 3.5s 1 loadFromBtm;
    animation-fill-mode: forwards; 
}

.snappedLBP6{
    animation: 1s ease-out 4s 1 loadFromBtm;
    animation-fill-mode: forwards; 
}

