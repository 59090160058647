
/* Add position fixed and add the styling again */

#header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: -webkit-sticky;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 1;
}

#logoText{
  /* color: #5aaef8; */
  color: #6246ea;
  font-size: 30px;
  padding-left: 30px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  animation: 1s ease-out 1.5s 1 loadFromBtm;
  animation-fill-mode: forwards;  
  opacity: 0;
  /* text-shadow: -2px 2px 0px #ffffff; */
  /* text-shadow: 2px 1px 0px #ffffff; */
}

#logoText:hover{
  /* color: #a7a7fa; */
  font-size: 33px;
}

#dropdown{
  list-style: none; 
  padding: 0;
}

.darkMode{
  background-color: #16161a;
}

.lightMode{
  background-color: #fffffe;
}

.Lpurple {
  background-color: rgb(167, 167, 250);
}

#logoHREF{
  text-decoration: none;
}

.headerItem{
  color: rgb(0, 0, 0);
  font-weight: 600;
  line-height: 3;
  font-family: 'Gill Sans', 'GillSansCRegular', Arial, sans-serif;
  font-size: 23px;
  letter-spacing: 0.1em;
  /* background-color: #6246ead9; */
  background-color: #a7a7fa;
  text-transform: uppercase;
}

.hLink{
  color: inherit;
  text-decoration: none;
  /* background-color: #7a61f0d9; */
  background-color: #a7a7fa;
  transition-duration: 0.5s;
  
  



  padding: 8px;
    /* border-style: dashed; */
    border-color: white;
}

.hLink:hover{
  color: white;
  background-color: black;
  border-style: outset;
  cursor: pointer
}


#burgerMenu{
    position: relative;
    display: flex;
    align-items: center;
    animation: 1s ease-out 1.5s 1 loadFromBtm;
    transition: 0.3s ease-in-out;
}

#menuContent{
  display: none; 
  color: #212121;
  position: absolute;
  right: -285px;
  /* background-color: #6246ead9; */
  background-color: #a7a7fa;
  border-bottom-left-radius: 6px;
  /* left: 86%;
  padding: 77px;
  padding-top: 70px;
  padding-bottom: 70px; */
  top: 0;
  /* transition-duration: 0.5s; */
  transition: all .5s ease;
  z-index: 1;
  border-style: solid;
  border-top: white;
  /* overflow-x: hidden;
  max-width: 100%; */
  /* left: 86.5%; */
  /* left: 100%; */
  padding: 46px;
  padding-top: 70px;
  padding-bottom: 22px;
  border-right: white;
  animation: '';
  padding-right: 30px;

  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px; */

  /* box-shadow: #6246ea -5px 5px, #745ce8 -10px 10px, #816be8 -15px 15px, #998ae0 -20px 20px, #b4abdf -25px 25px; */

  box-shadow: #6246ea -5px 5px, #ffffff -10px 10px, #9182df -15px 15px;
  /* For Dark Mode for certain things */
  /* box-shadow: #733eff -5px 5px, #733eff -10px 10px, #7054ff -15px 15px; */
  border-color: white;

  /* box-shadow: #6246ea -5px 5px, #7964e0 -10px 10px, #9182df -15px 15px; */
  
}

@keyframes slideLeft {
  100% { 
    /* left: 86.5%;  */
    left: "";
    right: 0;
  }
}

@keyframes slideRight {
  0%{
    /* left: 86.5%; */
    right: 0;
  }
  100%{
    left: 100%;
    right: "";
  }
}

.night{
  background-position: 0 0;
}
.content{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}
.content > i{
font-size: 44px;
transition: all .2s ease-in;
}
/* custom checkbox*/
.switch {
  position: relative;
  display: block;
  height: 20px;
  width: 44px;
  background: #303030;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.switch:after {
  position: absolute;
  left: -2px;
  top: -3px;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 100px;
  background: black;
  box-shadow: 0px 3px 3px rgba(0,0,0,0.05);
  content: '';
  transition: all 0.3s ease;
}
.switch:active:after { transform: scale(1.15, 0.85); }
.cbx:checked ~ label { background: #EBEBEB; }
.cbx:checked ~ label:after {
  left: 20px;
  background: #FFFFFF;
}
.cbx:disabled ~ label {
  background: #d5d5d5;
  pointer-events: none;
}
.cbx:disabled ~ label:after { background: #bcbdbc; }
.hidden { display: none; }


.btn11 {
    width: 60px;
    height: 60px;
    top: 300px;
    left: 240px;
    transition-duration: 0.5s;
  }
  .btn11 .icon-left {
    transition-duration: 0.5s;
    position: absolute;
    height: 4px;
    width: 15px;
    top: 30px;
    background-color: #212121;
  }
  .btn11 .icon-left:before {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 4px;
    background-color: #212121;
    content: "";
    top: -10px;
  }
  .btn11 .icon-left:after {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 4px;
    background-color: #212121;
    content: "";
    top: 10px;
  }
  .btn11 .icon-left:hover {
    cursor: pointer;
  }
  .btn11 .icon-right {
    transition-duration: 0.5s;
    position: absolute;
    height: 4px;
    width: 15px;
    top: 30px;
    background-color: #212121;
    left: 15px;
  }
  .btn11 .icon-right:before {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 4px;
    background-color: #212121;
    content: "";
    top: -10px;
  }
  .btn11 .icon-right:after {
    transition-duration: 0.5s;
    position: absolute;
    width: 15px;
    height: 4px;
    background-color: #212121;
    content: "";
    top: 10px;
  }
  .btn11.open .icon-left {
    transition-duration: 0.5s;
    background: transparent;
  }
  .btn11.open .icon-left:before {
    transform: rotateZ(45deg) scaleX(1.4) translate(2px, 2px);
  }
  .btn11.open .icon-left:after {
    transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -2px);
  }
  .btn11.open .icon-right {
    transition-duration: 0.5s;
    background: transparent;
  }
  .btn11.open .icon-right:before {
    transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 2px);
  }
  .btn11.open .icon-right:after {
    transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -2px);
  }
  .btn11:hover {
    cursor: pointer;
  }
  
  @keyframes loadFromBtm {
    0% {
        transform: translateY(100px);
      }
      100% {
        transform: translateY(0);
        opacity: 100%;
      }
  }


  .menuDark{

  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .hLink{
      box-shadow: none !important;
      border-radius: 3px !important;
      border-style: groove !important;
    }
}

@supports (-webkit-backdrop-filter: blur(1px)) {
  .hLink{
    box-shadow: none !important;
    border-radius: 3px !important;
    border-style: solid;
  }
}