#projectInfoImg{
    width: 100%;
    height: 360px;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

#projectInfoDesc{
    margin-top: -10px;
}

#projectInfoContainer{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background-color: white;
}

#close {
    overflow: hidden;
    position: relative;
    border: none;
    padding: 0;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background: transparent;
    color: #000000;
    font: inherit;
    text-indent: 100%;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    background: white;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, #7a61f0 0px 0px 0px 3px; */
}
#close:focus {
    outline: solid 0 transparent;
    /* box-shadow: 0 0 0 2px #8ed0f9; */
}
#close:hover {
    background: rgb(7 7 7);
    color: #ffffff;
}
#close:before, #close:after {
    position: absolute;
    top: 15%;
    left: calc(50% - .0625em);
    width: 0.125em;
    height: 70%;
    border-radius: 0.125em;
    transform: rotate(45deg);
    background: currentcolor;
    content: '';
}
#close:after {
    transform: rotate(-45deg);
}




#closeBtnContainer{
    position: absolute;
    left: 80%;
    /* top: -18%; */
    top: 3%;
}
/* 
#closePic{
    width: 25px;
    height: 25px;
    background: rgba(0, 0, 0, 0);
    cursor: pointer;
    
}

.close-container {
    position: relative;
    margin: auto;
    width: 50px;
    height: 50px;
    margin-top: 100px;
    cursor: pointer;
}
.leftright {
    height: 3px;
    width: 30px;
    position: absolute;
    margin-top: 24px;
    background-color: #000000;
    border-radius: 2px;
    transform: rotate(45deg);
    transition: all 0.3s ease-in;
}

.rightleft {
    height: 3px;
    width: 30px;
    position: absolute;
    margin-top: 24px;
    background-color: #000000;
    border-radius: 2px;
    transform: rotate(-45deg);
    transition: all 0.3s ease-in;
}
#exitlabel {
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all 0.3s ease-in;
    opacity: 0;
}
.close {
    margin: 60px 0 0 5px;
    position: absolute;
}
.close-container:hover .leftright {
    transform: rotate(-45deg);
    background-color: #f25c66;
}
.close-container:hover .rightleft {
    transform: rotate(45deg);
    background-color: #f25c66;
}
.close-container:hover label {
    opacity: 1;
} */

#buttonsContainer{
    margin-top: 10px;
}



#demoBtn {
  appearance: button;
  background-color: #000;
  background-image: none;
  border: 1px solid #000;
  border-radius: 4px;
  box-shadow: #fff 4px 4px 0 0,#000 4px 4px 0 1px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: ITCAvantGardeStd-Bk,Arial,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 5px 10px 0;
  overflow: visible;
  padding: 12px 24px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  margin-right: 25px;
}

#demoBtn:focus {
  text-decoration: none;
}

#demoBtn:hover {
  /* text-decoration: none; */
  background-color: #16161a;
  color: white;
}

#demoBtn:active {
  box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
  outline: 0;
}

#demoBtn:not([disabled]):active {
  box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
  transform: translate(2px, 2px);
}

/* @media (min-width: 768px) {
  #demoBtn {
    padding: 12px 50px;
  }
} */



#codeBtn {
    appearance: button;
    background-color: #000;
    background-image: none;
    border: 1px solid #000;
    border-radius: 4px;
    box-shadow: #fff 4px 4px 0 0,#000 4px 4px 0 1px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: ITCAvantGardeStd-Bk,Arial,sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 5px 10px 0;
    overflow: visible;
    padding: 12px 27px;
    text-align: center;
    text-transform: none;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    margin-right: 30px;
  }
  
  #codeBtn:focus {
    text-decoration: none;
  }
  
  #codeBtn:hover {
    text-decoration: none;
  }
  
  #codeBtn:active {
    box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
    outline: 0;
  }
  
  #codeBtn:not([disabled]):active {
    box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
    transform: translate(2px, 2px);
  }




   .arrow {
    cursor: pointer;
    display: block;
    width: 21.216407355px;
    height: 42.43281471px;
    position: relative;
    /* animation: blink 2s linear infinite; */
 }

 #logoBox{
  margin-top: 24px;
}

   .arrow span, .arrow:before, .arrow:after {
    background: #fff;
    content: '';
    display: block;
    width: 18px;
    height: 2px;
    border-radius: 30px;
    position: absolute;
    top: calc(50% - (2px / 2));
 }

 @keyframes blink{
  0%{opacity: 0;}
  25%{opacity: .5}
  50%{opacity: 1;}
  100%{opacity: 1;}
  }

   .arrow:before {
    transform: rotate(-45deg);
 }
   .arrow:after {
    transform: rotate(45deg);
 }
   .arrow span {
    width: 0;
 }
   .arrow:hover span {
    width: 42.42px;
 }
   .arrow.arrow--left span,  .arrow.arrow--left:before,  .arrow.arrow--left:after {
    left: 0;
    transform-origin: left 50%;
 }
   .arrow.arrow--left:before,  .arrow.arrow--left:after {
    transition: left 0.3s 0.05s;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(0, 0, 0) 0px 0px 0px 3px;
 }
   .arrow.arrow--left span {
    transition: width 0.3s, left 0.3s 0.05s;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px; */
 }
   .arrow.arrow--left:hover span,  .arrow.arrow--left:hover:before, .arrow.arrow--left:hover:after {
    left: -21.216407355px;
   }

   @media(max-width: 500px) and (min-height: 851px) {
      #projectInfoBox{
        height: 71vh;
      }

      #buttonsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 25px;
        margin-bottom: 8px;
      }

      #projectInfoImg{
        border-radius: 4px;
      }

      #logoBox{
        margin-top: 5px;
      }

      #projectInfoContainer{
        width: 90%;
        border-radius: 4px;
      }
   }

   @media(max-width: 500px) {
    #projectInfoBox{
      height: 73vh;
    }

    #projectInfoImg{
      min-height: 186px;
    }

    #buttonsContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 21px;
      margin-bottom: 8px;
    }

    #projectInfoImg{
      border-radius: 4px;
    }

    #logoBox{
      margin-top: 5px;
      margin-bottom: 25px;
    }

    #projectInfoContainer{
      width: 90%;
      border-radius: 4px;
    }
   }