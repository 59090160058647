#contactTitleContainer{
  /* padding-top: 70px; */
  /* padding-top: 45px; */
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding-bottom: 20px;
  opacity: 0;
}

#contactTitleContainer{
    display: flex;
    flex-direction: column;
}

#contactTitle{
    font-size: 60px;
}

#contactSubtitle{
    font-size: 40px;
    margin-top: -30px;
}

#formContainer{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    opacity: 0;
}

#form{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 46px;
}

#contactInfo{
    display: flex;
    flex-direction: row;
    margin-right: 26.5%;
    /* gap: 30%; */
    justify-content: space-around;
    gap: 36%;
}

#name{
    padding: 10px;
    width: 300px;
    border-style: solid;
    border-radius: 3px;
    border-bottom: ridge;
    border-right-color: #ffffff00;
    border-top-color: #ffffff00;
    border-left-color: #ffffff00;
    border-bottom-color: #6246ea;
}

#name:focus-visible{
  border-bottom-color: #000000;
  outline: none;
}

#email{
    padding: 10px;
    width: 300px;
    border-style: solid;
    border-radius: 3px;
    border-bottom: ridge;
    border-right-color: #ffffff00;
    border-top-color: #ffffff00;
    border-left-color: #ffffff00;
    border-bottom-color: #6246ea;
}

#email:focus-visible{
    border-bottom-color: #000000;
    outline: none;
}

#message{
    padding: 10px;
    /* width: 300px; */
    border-style: solid;
    border-radius: 3px;
    border-bottom: ridge;
    border-right-color: #ffffff00;
    border-top-color: #ffffff00;
    border-left-color: #ffffff00;
    border-bottom-color: #6246ea;
    /* width: 812px; */
    width: 820px;
}

#message:focus-visible{
    border-bottom-color: #000000;
    outline: none;
}

#nameTitle{
    margin-right: 10px;
}

#emailTitle{
    margin-right: 10px;
}

#messageTitle{
    margin-right: 10px;
}

#nameContainer{
  display: flex;
  flex-direction: column;
  flex-direction: column;
}

#emailContainer{
  display: flex;
  flex-direction: column;
  flex-direction: column;
}

#contactText{
    /* margin-top: 40px;
    display: flex;
    flex-direction: column; */
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    /* margin-left: -142px; */
    padding-left: 146px;
    margin-left: -149px;
}

#sendBtnContainer{
    margin-top: 50px;
}

#sendBtn{
    background: #6246ea;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding: 1rem 3rem;
    transition: 0.2s;
    cursor: pointer;
}

#sendBtn:hover{
    letter-spacing: 0.2rem;
  padding: 1.1rem 3.1rem;
  background: var(--clr);
  color: var(--clr);
  /* box-shadow: 0 0 35px var(--clr); */
  animation: box 3s infinite;
}

#sendBtn::before{
    content: "";
  inset: 2px;
  background: #272822;
}

#sendBtn i {
    inset: 0;
    display: block;
  }
  
#sendBtn i::before {
    content: "";
    width: 10px;
    height: 2px;
    left: 80%;
    top: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
  }
  
  #sendBtn:hover i::before {
    width: 15px;
    left: 20%;
    animation: move 3s infinite;
  }
  
  #sendBtn i::after {
    content: "";
    width: 10px;
    height: 2px;
    left: 20%;
    bottom: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
  }
  
  #sendBtn:hover i::after {
    width: 15px;
    left: 80%;
    animation: move 3s infinite;
  }

  
  #sendBtnDark{
    background: #6246ea;
    color: rgb(255, 255, 255);
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding: 1rem 3rem;
    transition: 0.2s;
    cursor: pointer;
}



  #sendBtnDark:hover{
    /* letter-spacing: 0.2rem;
  padding: 1.1rem 3.1rem;
  background: var(--clr); */
  /* color: var(--clr); */
  /* box-shadow: 0 0 35px var(--clr); */
  /* animation: box 3s infinite; */

  /* letter-spacing: 0.2rem;
  padding: 1.1rem 3.1rem;
  background: white;
  border-radius: 3px;
  color: black; */
  /* box-shadow: 0 0 35px var(--clr); */
  /* animation: box 3s infinite; */

  /* border-color: #6246ea;
  border-style: dashed;
  border-width: 2px; */

  letter-spacing: 0.2rem;
  padding: 1.1rem 3.1rem;
  background: white;
  border-radius: 3px;
  color: black;
  box-shadow: #6246ea 9px 10px 1px 0px;
}

#sendBtnDark::before{
    content: "";
  inset: 2px;
  background: #272822;
}

#sendBtnDark i {
    inset: 0;
    display: block;
  }
  
#sendBtnDark i::before {
    content: "";
    width: 10px;
    height: 2px;
    left: 80%;
    top: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
  }
  
  #sendBtnDark:hover i::before {
    width: 15px;
    left: 20%;
    animation: move 3s infinite;
  }
  
  #sendBtnDark i::after {
    content: "";
    width: 10px;
    height: 2px;
    left: 20%;
    bottom: -2px;
    border: 2px solid var(--clr);
    background: #272822;
    transition: 0.2s;
  }
  
  #sendBtnDark:hover i::after {
    width: 15px;
    left: 80%;
    animation: move 3s infinite;
  }
  
  
  @keyframes move {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes box {
    0% {
      box-shadow: #27272c;
    }
    50% {
      box-shadow: 0 0 25px var(--clr);
    }
    100% {
      box-shadow: #27272c;
    }
  }
  

#footerText{
    display: flex;
    margin-top: 180px;
    bottom: 10%;
    justify-content: center;
    align-items: center;
    gap: 28px;
    opacity: 0;
}

.rectSpan{
    background-color: #6246ea;
    width: 41px;
    height: 2px;
}

#footerLinkedin{
    text-decoration: none;
    color: black;
    transition: 0.3s ease-in-out;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-color: #00000000;
    
}

#footerLinkedin:hover{
    border-color: #6246ea;
}

#footerEmail{
    text-decoration: none;
    color: black;
    transition: 0.3s ease-in-out;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-color: #00000000;
}

#footerEmail:hover{
    border-color: #6246ea;
}

#footerGithub{
    text-decoration: none;
    color: black;
    transition: 0.3s ease-in-out;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-color: #00000000;
}

#footerGithub:hover{
    border-color: #6246ea;
}

#copyrightLogo{
    /* width: 30px;
    height: 30px; */

    width:15px;
    height: 15px;
    margin-right: 3px;
}

#copyrightText{
    font-size: 12px;
}

#copyrightContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    user-select: none;
    opacity: 0;
}

/* .whiteUnderline:focus-visible{
    border-bottom-color: white;
}

.blackUnderline:focus-visible{
    border-bottom-color: #000000;
} */

.snappedLBContact{
  animation: 1s ease-out 1.5s 1 loadFromBtm;
  animation-fill-mode: forwards; 
}

.snappedLBContact2{
  animation: 1s ease-out 2.0s 1 loadFromBtm;
  animation-fill-mode: forwards; 
}

@media(max-width: 970px) {

  #contactTitleContainer{
    padding-bottom: 0;
    text-align: center;
  }

  #footerText{
    margin-top: 150px;
  }

  #contactInfo{
    /* gap: 5%; */
    gap: 14%;
    margin-right: 32.5px;
  }

  #email,#name{
    width: 250px;
  }

  #message{
    width: 573px;
  }

  #contactText{
    margin-left: -247px;
    padding-left: 215px;
  }
}

@media (max-width: 500px) and (min-height: 851px) {
  #contactTitleContainer{
    padding-top: 75px;
  }

  #contactTitle{
    font-size: 9vw;
    margin-bottom: 60px;
  }

  #contactSubtitle{
    font-size: 7vw;
  }

  #nameContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: column;
  }

  #emailContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: column;
  }

  #contactText{
    margin-top: 0;
    margin-left: 0; 
    padding-left: 0;
    display: flex;
    align-items: center;
  }

  #contactInfo{
    flex-direction: column;
  }

  #message{
    width: 254px;
  }

  #footerText{
    margin-top: 100px;
  }
}

@media(max-height: 850px) and (max-width: 500px) {
  #contactTitleContainer{
    padding-top: 45px;
  }

  #contactTitle{
    font-size: 9vw;
    margin-bottom: 60px;
  }

  #contactSubtitle{
    font-size: 7vw;
  }

  #nameContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: column;
  }

  #emailContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: column;
  }

  #contactText{
    margin-top: 0;
    margin-left: 0; 
    padding-left: 0;
    display: flex;
    align-items: center;
  }

  #contactInfo{
    flex-direction: column;
    margin-right: 0px;
  }

  #message{
    width: 254px;
  }

  #footerText{
    margin-top: 100px;
  }
}

@media(min-height: 870px) and (min-width: 500px) {
  #formContainer {
    margin-top: 50px;
  }

  #footerText {
    margin-top: 180px;
  }
}