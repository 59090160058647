@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Kalam&family=Yellowtail&display=swap');

#transitionBox{
    position: fixed;
    height: 100vh;
    bottom: 0;
    right: 0;
    transition: ease-in-out .3s;
    width: 100%;
    background-color: #6246ea;
    /* opacity: 0; */
    transform: translateX(-100%);
    z-index: 3;
}

#transitionBoxTitle{
    font-size: 300px;
    color: white;
    text-align: center;
    /* font-family: 'Kalam', cursive; */
    font-family: 'Yellowtail', cursive;
}

@media(max-width: 500px) and (min-height: 851px) {
    #transitionBoxTitle{
        position: relative;
        top: 20%;
        font-size: 200px;
        left: -4%;
    }
}



@media(max-height: 850px) and (max-width: 500px) {
#transitionBoxTitle{
    top: 15%;
    font-size: 200px;
    left: -4%;
    position: relative;
  }
}