#welcomeAll{
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* Require custom margins
    justify-content: space-between; */
}


#welcomeContent{
    /* Half Screen View
    display: flex;
    flex-direction: column;
    margin-left: 150px;
    margin-top: 145px; */

    /* margin-top: 145px; */
    /* margin-top: 230px; */
    margin-top: 150px;
    display: flex;
    flex-direction: column;

    margin-left: 50px;

    /* for custom whitespace
    margin-left: 298px; */
    
}

#SMContainer{
  display: none;
  position: absolute;
  left: 2%;
  top: 42%;
  animation: 1s ease-out 0s 1 loadFromLeft;
  background-color: #ffffff00;
  z-index: 1;
}

#linkedin{
  background-color: #ffffff00;
}

#line{
  border-left: 3px solid #636269;
  height: 200px;
  margin-left: 18px;
  margin-top: 10px;
  background-color: #ffffff00;
}

#github{
  padding-top: 3px;
  background-color: #ffffff00;
}

#linkedinPic{
  cursor: pointer;
  width: 40px;
  opacity: 65%;
  height: 40px;
  transition: 0.2s ease-in-out;
  border-radius: 6px;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; */
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
}

#linkedinPic:hover{
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
  opacity: 100%;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; */
}

#githubPic{
  cursor: pointer;
  width: 40px;
  opacity: 65%;
  height: 40px;
  transition: 0.2s ease-in-out;
  border-radius: 6px;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; */
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
}

#githubPic:hover{
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; */
  opacity: 100%;
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
}

#profilePic{
    /* Half Screen View
    width: 407px;
    height: 393px;
    margin-right: 300px;
    margin-top: 200px;
    border-style: solid; */
    display: block;
    pointer-events: none;
    opacity: 0%;
    /* width: 500px; */
    width: 450px;

    /* margin-top: 163px; */
    /* margin-top: 215px; */
    margin-top: 140px;
    border-style: solid;
    border-color: black;
    border-radius: 10px;
    box-shadow: 9px 10px 3px 0px rgba(98,70,234,0.76);
    -webkit-box-shadow: 9px 10px 3px 0px rgba(98,70,234,0.76);
    -moz-box-shadow: 9px 10px 3px 0px rgba(98,70,234,0.76);
    transform: translateX(0px);
    animation: 1s ease-out 0s 1 loadFromBtmW;
    animation-delay: 3s;

    margin-left: 50px;

    /* for custom white space
    margin-right: 371px; */
    /* animation-delay: 1s; */
}

#subtitle{
  display: block;
  pointer-events: none;
  opacity: 0%;
  /* font-size: 40px; */
  font-size: 40px;
  animation: 1s ease-out 0s 1 loadFromBtmW;
  animation-delay: 3s;
}

#title{
  display: block;
  pointer-events: none;
  opacity: 0%;
  /* Half Screen
  font-size: 48px; */
  animation: 1s ease-out 0s 1 loadFromBtmW;
  animation-delay: 3.7s;
  /* font-size: 58px; */
  font-size: 58px;
  color: #6246ea;
}

#title::selection {
  background: #282525;
  cursor: pointer;
}

#paragraph1{
    display: block;
    pointer-events: none;
    opacity: 0%;
    color: #2b2c34;
    font-weight: bold;
    animation: 0.7s ease-out 0s 1 loadFromBtmW;
    animation-delay: 5s;
    /* font-size: 20px; */
    /* animation-delay: 1s; */
}

#paragraph2{
  display: block;
  pointer-events: none;
  opacity: 0%;
  color: #2b2c34;
  /* transition: ease-in-out 3s; */
  max-width: 500px;
  animation: 0.7s ease-out 0s 1 loadFromBtmW;
  animation-delay: 5s;
  /* font-size: 20px; */
    /* animation-delay: 1s; */
}

#mouseContainer{
    display: none;
    animation: 1s ease-out 0s 1 loadFromBtmW;
}

.icon-scroll {
    width: 33px;
    height: 55px;
    /* background: #ccc; */
    /* background: #6246ea; */
    background: #a7a7fa;
    /* border: 2px solid #bbb; */
    /* border: 2px solid black; */
    border: 2px solid #6246ea;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    margin: auto;
    position: relative;
    margin-top: 120px;
    box-shadow: 3px 3px 5px -5px #000;
    /* When not full page size remove the margin right below to center */

    /* For custom white space
    margin-right: 1000px; */
    
}

.icon-scroll::before {
    content: "";
    width: 4px;
    height: 10px;
    background: #eee;
    border-radius: 2px;
    margin-top: 5px;
  }

.icon-scroll::after {
    content: "";
    width: 4px;
    height: 3px;
    /* background-color: #bbb; */
    /* background-color: #2a2a2a; */
    background-color: #6246ea;
    position: absolute;
    display: block;
    animation: scroll ease 1s infinite;
  }

@keyframes scroll {
    from {
      top: 5px;
    }
    to {
      top: 18px;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes loadFromBtmW {
    0% {
        transform: translateY(100px);
      }
      100% {
        transform: translateY(0);
      }
  }

  @keyframes loadFromLeft {
    0% {
      transform: translateX(-100px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes invisible {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
  }

  @media (max-width: 970px) {
    #paragraph1, #paragraph2{
      font-size: 1.8vw;
    }

    #profilePic{
      width: 48vw;
    }

  }

  #welcomeSubtextMob{
    display: none;
    flex-direction: column;
    font-size: 3.5vw;
    max-width: 315px;
  }

  #paragraph1Mob{
    display: none;
    pointer-events: none;
    opacity: 0%;
    color: #2b2c34;
    font-weight: bold;
    animation: 0.7s ease-out 0s 1 loadFromBtmW;
    animation-delay: 5s;
}

#paragraph2Mob{
  display: none;
  pointer-events: none;
  opacity: 0%;
  color: #2b2c34;
  max-width: 500px;
  animation: 0.7s ease-out 0s 1 loadFromBtmW;
  animation-delay: 5s;
}

  @media (max-width: 500px) and (min-height: 851px) {
    #welcomeAll{
      flex-direction: column;
      /* margin-top: -113px; */
    }

    #welcomeContent{
      margin-top: 100px;
    }

    #title{
      margin-top: 0;
      margin-bottom: 20px;
    }

    #profilePic{
      margin-left: 0;
      margin-top: 37px;
      margin-bottom: 37px;
    }

    #SMContainer{
      top: 50%;
      /* position: relative; */
    }

    #mouseContainer{
      position: relative;
      top: -7%;
    }
  }

  /* Iphone 13 is 653px tall */
  @media(max-height: 850px) and (max-width: 500px) {
    #welcomeAll{
      flex-direction: column;
    }

    #welcomeContent{
      margin-top: 50px;
      margin-left: -53px;
    }

    #title{
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 10vw;
    }

    #subtitle{
      font-size: 8vw;
    }

    #profilePic{
      margin-left: 0;
      margin-top: 37px;
      margin-bottom: 37px;
    }

    #SMContainer{
      top: 50%;
    }

    #mouseContainer{
      top: -7%;
    }

    #profilePic{
      margin-top: 25px;
      margin-left: 0;
      margin-bottom: 37px;
    }

    #SMContainer{
      top: 40%;
    }

    #mouseContainer{
      position: relative;
      top: -13%;
    }
  }

  @media(min-height: 870px) and (min-width: 500px) {
    #welcomeAll{
      padding-top: 3%;
    }
  }

  /* Stop horizontal scroll */
  /* Everything needs to be much smaller, icons and grpahics manual but text the smae size, i'd say as large as about page title text */
  /* Remove linkedin and github logo */