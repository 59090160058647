#skillsContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* padding-top: 245px; */
    padding-top: 160px;
}

#skillsText{
    font-size: 40px;
    opacity: 0;
}

.snappedLB{
    animation: 1s ease-out 0.5s 1 loadFromBtm;
    animation-fill-mode: forwards; 
}

.snappedRS1{
    animation: 1s ease-out 1.5s 1 slideRight;
    animation-fill-mode: forwards;  
    opacity: 0;
}

.snappedRS2{
    animation: 1s ease-out 2.5s 1 slideRight;
    animation-fill-mode: forwards;  
    opacity: 0;
}

.snappedRS3{
    animation: 1s ease-out 3.5s 1 slideRight;
    animation-fill-mode: forwards;  
    opacity: 0;
}

#skillsContent{
    font-size: 20px;
}

.imageContainer{
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;
}

.icon {
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

#typescript_Logo{
    height: 35px;
    width: 35px;
    margin-top: 8px;
    margin-left: 5px;
}

#react_Logo{
    height: 44px;
    width: 40px;
    margin-top: 4px;
    margin-left: 5px;
    margin-right: 20px;
}

#next_Logo {
    height: 40px;
    width: 40px;
    margin-top: 5px;
    margin-right: 9px;
    transition: ease-in-out 0.3s;
}

#angular_Logo{
    height: 50px;
    width: 50px;
    margin-left: 5px;
    margin-right: 20px;
}

#AWS_Logo {
    height: 60px;
    width: 60px;
    margin-top: -5px;
}

#flask_Logo {
    transition: ease-in-out 0.3s;
}

#java_Logo{
    height: 40px;
    width: 40px;
    margin-top: 5px;
    margin-left: 8px;
}

#express_Logo{
    margin-right: 20px;
}

#mui_Logo{
    margin-right: 20px;
}

#node_Logo{
    margin-right: 20px;
}

#chartjs_Logo{
    margin-right: 20px;
}

@keyframes slideLeft {
    100% { 
      /* left: 86.5%;  */
      left: "";
      right: 0;
    }
  }
  
  @keyframes slideRight {
    0% {
        transform: translateX(400px);
        opacity: 0;
      }
      100% {
        transform: translateX(0);
        opacity: 100%;
      }
  }

  @keyframes loadFromBtm {
    0% {
        transform: translateY(400px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 100%;
      }
  }

#skillsTitleContainer{
    display: none;
}

@media (max-width: 970px) {
    #skillsContainer{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 0px;
    }

    #skillsText{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #word1Half,#word2Half{
        margin-bottom: 0px;
        padding-left: 20px;
    }

    #word1,#word2{
        display: none;
    }

    .imageContainer{
        display: flex;
        flex-direction: row;
        padding-bottom: 15px;
        justify-content: center;
    }

    #skillsTitleContainer{
        display: flex;
        margin-top: 15px;
    }
}

@media(max-width: 500px) and (min-height: 500px) {
    #skillsTitleContainer{
        font-size: 6vw;
        margin-top: 110px;
    }

    #word1Half, #word2Half{
        padding-left: 10px;
    }

    #word3{
        font-size: 7.645vw;
    }

    #title{
        font-size: 5.2vw;
    }

    .icon{
        width: 8vw;
        height: 8vw;
    }

    #typescript_Logo{
        height: 5vw;
        width: 5vw;
        margin-top: 7px;
    }

    #java_Logo{
        height: 6vw;
        width: 6vw;
    }

    #cShapr_Logo, #cPlusPlusLogo, #pythonLogo{
        width: 7vw;
        height: 7vw;
        margin-top: 3px;
    }

    #angular_Logo{
        height: 9vw;
        width: 9vw;
    }
    
    #AWS_Logo {
        height: 10vw;
        width: 10vw;
    }

    #express_Logo{
        display: none;
    }

    #bootstrap_Logo {
        display: none;
    }

    #tailwindcss_Logo {
        display: none;
    }
    
    #docker_Logo {
        height: 9vw;
        width: 9vw;
        margin-top: -5px;
        margin-left: 15px;
    }
      

    #react_Logo {
        height: 8vw;
        width: 7vw;
        margin-top: 2px;
        margin-left: 15px;
    }

    #next_Logo {
        margin-top: 4px;
        width: 30px;
        height: 30px;
    }

    #skillsContent{
        margin-top: 50px;
    }
}

/* @media(max-height: 799px) and (max-width: 500px) {
    #skillsTitleContainer{
        font-size: 6vw;
        margin-top: 100px;
    }

    #word1Half, #word2Half{
        padding-left: 10px;
    }

    #word3{
        font-size: 7.645vw;
    }

    #title{
        font-size: 5.2vw;
    }

    .icon{
        width: 8vw;
        height: 8vw;
    }

    #typescript_Logo{
        height: 5vw;
        width: 5vw;
        margin-top: 7px;
    }

    #java_Logo{
        height: 6vw;
        width: 6vw;
    }

    #cShapr_Logo, #cPlusPlusLogo, #pythonLogo{
        width: 7vw;
        height: 7vw;
        margin-top: 3px;
    }

    #react_Logo{
        display: none;
    }

    #bootstrap_Logo {
        display: none;
    }

    #tailwindcss_Logo {
        display: none;
    }

    #skillsContent{
        margin-top: 50px;
    }
    #skillsText{
        margin-top: -55px;
      }
    } */

@media(min-height: 870px) and (min-width: 500px) {
    #skillsContainer{
        align-items: center;
        display: flex;
        justify-content: space-around;  
        padding-top: 200px;
    }
}

