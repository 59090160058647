.night{
    background-position: 0 0;
}
.content{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-top: 3px;
}
.content > i{
  font-size: 44px;
  transition: all .2s ease-in;
}
/* custom checkbox*/
.switch {
    position: relative;
    display: block;
    height: 20px;
    width: 44px;
    background: #303030;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .switch:after {
    position: absolute;
    left: -2px;
    top: -3px;
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 100px;
    background: black;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.05);
    content: '';
    transition: all 0.3s ease;
  }
  .switch:active:after { transform: scale(1.15, 0.85); }
  .cbx:checked ~ label { background: #EBEBEB; }
  .cbx:checked ~ label:after {
    left: 20px;
    background: #FFFFFF;
  }
  .cbx:disabled ~ label {
    background: #d5d5d5;
    pointer-events: none;
  }
  .cbx:disabled ~ label:after { background: #bcbdbc; }
  .hidden { display: none; }